import SvgQuestionTooltip from 'assets/pricing/question-tooltip.inline.svg';
import Button from 'components/material-ui/Button';
import TooltipCode from 'components/material-ui/TooltipCode';
import { PRICING_METHOD_OF_CALCULATION } from 'components/pages/pricing/base/enumsPricing';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useLanguage from 'hooks/useLanguage';
import React from 'react';
import { formatValueToEurope } from 'utils/helper';

export default function BasketItem({ item, onClickAdd }) {
	const { t } = useTranslation();
	const { currency } = useLanguage();

	return (
		<div className="sm:w-80/100 md:w-70/100 xl:w-60/100 flex items-start sm:items-center justify-between sm:mb-05">
			<Button data-cy="btnAdd" tw="w-60 py-02 ml-0 mr-10 text-center" size="small" onClick={() => onClickAdd(item)}>
				{t('pageBasket.label.btnAdd')}
			</Button>

			<div className="w-full flex flex-col sm:flex-row items-start sm:items-center justify-between">
				<div className="flex">
					<p>
						{item.details.name}
						<div className="inline-block">
							<TooltipCode Content={item.details.tooltip}>
								<SvgQuestionTooltip/>
							</TooltipCode>
						</div>
					</p>
				</div>
				<div className="flex items-center mt-07 mb-12 sm:my-0">
					<p className="text-grey-6 line-through">
						{item?.optionCostForCurrency?.promoCost && (
							<span className="mr-05">{formatValueToEurope(item.optionCostForCurrency.cost, currency.code)}</span>
						)}
					</p>
					<p className="text-12 text-green-1 font-bold sm:ml-10">
						{item?.optionCostForCurrency?.cost ? (
							formatValueToEurope(item?.optionCostForCurrency?.promoCost || item.optionCostForCurrency.cost, currency.code)
							// Custom method without price
						) : item.customMethod}

						{/* Price for every order */}
						{item.methodOfCalculation === PRICING_METHOD_OF_CALCULATION.FOR_EVERY_ORDER && (
							<span className="absolute pl-03 font-normal text-10">{t('pageBasket.basketItem.order')}</span>
						)}
					</p>

					{/* Custom method with price */}
					{item?.optionCostForCurrency?.cost && item.methodOfCalculation === PRICING_METHOD_OF_CALCULATION.OTHER && (
						<p className="text-12 text-green-1 font-bold sm:ml-10">
							<span className="absolute pl-03 font-normal text-10">{item.customMethod}</span>
						</p>
					)}
				</div>
			</div>
		</div>
	);
}
