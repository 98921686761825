import { useLocation } from '@reach/router';
import ChipTool from 'components/material-ui/ChipTool';
import BasketItem from 'components/pages/basket/items/BasketItem';
import { PRICING_METHOD_OF_CALCULATION, PRICING_PLAN, PRICING_PLAN_OPTION_TYPE } from 'components/pages/pricing/base/enumsPricing';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import parse from 'html-react-parser';
import { actionsPersist, usePersistDispatch } from 'providers/PersistProvider';
import { compose, flatten, path, sortBy } from 'ramda';
import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { formatValueToEurope, isBrowser } from 'utils/helper';

const getPrice = (months, methodOfCalculation, cost) => {
	switch (methodOfCalculation) {
		// case PRICING_METHOD_OF_CALCULATION.YEARLY:
		// 	return Number(months / 12 * cost);
		// case PRICING_METHOD_OF_CALCULATION.MONTHLY:
		// 	return Number(months / 1 * cost);
		case PRICING_METHOD_OF_CALCULATION.FOR_EVERY_ORDER:
			return 0;
		case PRICING_METHOD_OF_CALCULATION.OTHER:
			return 0;
		default:
			return Number(cost);
	}
};

export default function ShoppingCard({ isMobile, allPlanSetups, optionAgreement, modeName, onAgreementOptionChange }) {
	const { t } = useTranslation();
	const location = useLocation();
	const dispatchPersist = usePersistDispatch();

	const [isInitialRender, setInitialRender] = useState(true);

	const plan = allPlanSetups.find(item => item.name === PRICING_PLAN[modeName]);
	const planCurrentOption = plan?.pricingSetting?.find(item => item?.planDuration?.duration === optionAgreement.durationInMonths);

	const initialOptionItems = plan?.configCategories?.map(configCategory => configCategory.configOptions?.filter(item => (
		// / gratis
		(item.methodOfCalculation === PRICING_METHOD_OF_CALCULATION.OTHER && item.customMethod) ||
		((item.optionType === PRICING_PLAN_OPTION_TYPE.PAY_EXTRA) && (item?.optionCostForCurrency?.promoCost || item?.optionCostForCurrency?.cost))
	))) ?? [];

	const initialPrice = Number(planCurrentOption?.promoPrice ? planCurrentOption?.price : null);
	const initialPromoPrice = Number(planCurrentOption?.promoPrice || planCurrentOption?.price);

	const [price, setPrice] = useState(initialPrice);
	const [promoPrice, setPromoPrice] = useState(initialPromoPrice);

	const [basketItems, setBasketItems] = useState([]);

	const sortByName = sortBy(compose(path(['details', 'name'])));
	const [optionItems, setOptionItems] = useState(flatten(initialOptionItems));

	const isInaccessible = !price && !promoPrice;

	// TODO: use context instead drying props
	useEffect(() => {
		if (isInaccessible) {
			onAgreementOptionChange(true);
		} else {
			onAgreementOptionChange(false);
		}
	}, [isInaccessible]);

	useEffect(() => {
		if (isBrowser && !isInitialRender) {
			window.location.reload();
		}
		setInitialRender(false);
	}, [location.hash]);

	// recalculate basket
	useEffect(() => {
		let updatedPrice = 0;
		let updatedPromoPrice = 0;

		for (const item of basketItems) {
			// TODO: redundant
			const costOptions = item?.optionCostForCurrency;
			const promoCost = (costOptions?.promoCost ? costOptions?.promoCost : costOptions?.cost) ?? 0;
			const cost = costOptions?.cost ?? 0;

			updatedPromoPrice += getPrice(optionAgreement.durationInMonths, item.methodOfCalculation, promoCost);
			updatedPrice += getPrice(optionAgreement.durationInMonths, item.methodOfCalculation, cost);
		}

		const currentPrice = initialPrice + updatedPrice;
		const currentPromoPrice = initialPromoPrice + updatedPromoPrice;

		setPrice(currentPrice);
		setPromoPrice(currentPromoPrice);

		// save basket and total price for summary
		dispatchPersist({
			type: actionsPersist.updatePersistData, payload: {
				pricingPlan: PRICING_PLAN[modeName],
				totalCost: planCurrentOption?.promoPrice ? currentPromoPrice : currentPrice,
				currency: planCurrentOption?.currency,
				basket: basketItems ?? []
			}
		});
	}, [optionAgreement, basketItems]);

	const isSilver = modeName === PRICING_PLAN.SILVER;
	const isGold = modeName === PRICING_PLAN.GOLD;
	const isPlatinum = modeName === PRICING_PLAN.PLATINUM;

	const handleAddToBasket = currentOption => {
		const costOptions = currentOption?.optionCostForCurrency;
		const promoCost = (costOptions?.promoCost ? costOptions?.promoCost : costOptions?.cost) ?? 0;
		const cost = costOptions?.cost ?? 0;

		setPromoPrice(prev => prev + getPrice(optionAgreement.durationInMonths, currentOption.methodOfCalculation, promoCost));
		setPrice(prev => prev + getPrice(optionAgreement.durationInMonths, currentOption.methodOfCalculation, cost));

		setOptionItems(prev => ([...prev.filter(item => item.id !== currentOption.id)]));
		setBasketItems(prev => ([...prev, currentOption]));
	};

	const handleRemoveFromBasket = currentOption => {
		const costOptions = currentOption?.optionCostForCurrency;
		const promoCost = (costOptions?.promoCost ? costOptions?.promoCost : costOptions?.cost) ?? 0;
		const cost = costOptions?.cost ?? 0;

		setPromoPrice(prev => prev - getPrice(optionAgreement.durationInMonths, currentOption.methodOfCalculation, promoCost));
		setPrice(prev => prev - getPrice(optionAgreement.durationInMonths, currentOption.methodOfCalculation, cost));

		setBasketItems(prev => ([...prev.filter(item => item.id !== currentOption.id)]));
		setOptionItems(prev => ([...prev, currentOption]));
	};

	return (
		<div className="mx-10 my-24 sm:my-30">
			<div style={{ maxWidth: isMobile ? '24rem' : '75rem' }} className="h-full relative border-2 border-grey-E rounded-10 bg-light mx-auto">
				{isSilver && <div style={{ marginTop: '-0.25rem' }} className="absolute ml-12 xl:ml-22 top-0 w-40 h-03 xl:h-04 bg-grey-C rounded-10"/>}
				{isGold && <div style={{ marginTop: '-0.25rem' }} className="absolute ml-12 xl:ml-22 top-0 w-30 h-03 xl:h-04 bg-accent-0 rounded-10"/>}
				{isPlatinum && <div style={{ marginTop: '-0.25rem' }} className="absolute ml-10 xl:ml-20 top-0 w-60 h-03 xl:h-04 bg-blue-1 rounded-10"/>}

				<div className="px-12 sm:px-12 xl:px-22">
					<div className="sm:flex sm:items-center sm:justify-between my-10 sm:my-15">
						<p className="text-14 font-bold mb-15 sm:mb-0">{modeName.toUpperCaseChar()}</p>
						<div className="sm:flex sm:items-center">
							<p className="text-12 text-grey-6 line-through">{planCurrentOption?.promoPrice && formatValueToEurope(price, planCurrentOption?.currency?.code)}</p>
							<p
								className="text-15 font-bold mt-07 sm:mt-0 mb-07 sm:ml-10 sm:mb-03"
								css={[!isInaccessible && tw`text-green-1`]}
							>
								<span data-cy="shoppingCardPriceValue" className="hidden">{promoPrice}</span>
								{isInaccessible ? t('pagePricing.plan.inaccessible') : formatValueToEurope(promoPrice, planCurrentOption?.currency?.code)}
							</p>
						</div>
						<p style={{ minHeight: '2.8rem' }} className="leading-14 text-grey-6 sm:w-1/2 pb-05 sm:pb-0">{planCurrentOption?.priceDescription}</p>
					</div>

					<div className="border-t border-b border-solid border-grey-D">
						<p className="text-grey-1 my-15 sm:my-20">{parse(plan?.description ?? '')}</p>
						<p className="text-11 font-bold text-grey-1">{t('pageBasket.inBasket')}</p>

						<div>
							{!basketItems?.length && <p className="text-green-1 h-24">{t('pageBasket.basketEmpty')}</p>}
							{basketItems.map(item => (
								<ChipTool
									tw="my-07"
									key={item.id}
									label={item.details.name}
									Content={item.details.tooltip}
									onDelete={() => handleRemoveFromBasket(item)}
								/>
							))}
						</div>
					</div>

					<div className="my-15 sm:my-24">
						<p className="text-11 font-bold text-grey-1 mb-15 sm:mb-10">{t('pageBasket.options')}</p>
						{!optionItems?.length && <p className="text-green-1">{t('pageBasket.optionsEmpty')}</p>}
						{sortByName(optionItems).map(item => <BasketItem key={item.id} item={item} onClickAdd={handleAddToBasket}/>)}
					</div>
				</div>
			</div>
		</div>
	);
}

ShoppingCard.defaultProps = {
	mode: {}
};
