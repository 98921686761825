import ChipCore from '@mui/material/Chip';
import SvgClose from 'assets/general/close.inline.svg';
import SvgQuestionTooltip from 'assets/pricing/question-tooltip.inline.svg';
import IconButton from 'components/base/IconButton';
import TooltipCode from 'components/material-ui/TooltipCode';
import React from 'react';
import tw, { css } from 'twin.macro';

const cssChip = css`
	${tw`rounded-08 bg-grey-F px-04 py-12`};
	
	.MuiChip-label {
		${tw`pl-03 pr-0`}
	}
`;

export default function ChipTool({ Content, onDelete, ...props }) {
	return (
		<>
			<ChipCore
				css={[ cssChip ]}
				deleteIcon={Content ? (
					<TooltipCode Content={Content}>
						<SvgQuestionTooltip />
					</TooltipCode>
				) : <div className='pr-03' />}
				onDelete={() => {}}
				{...props}
			/>
			<IconButton onClick={onDelete}>
				<SvgClose width='1.2rem' height='1.2rem' />
			</IconButton>
		</>
	);
}

ChipTool.defaultProps = {
	Content: <div />,
	label: ''
};
