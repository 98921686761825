import { useLocation } from '@reach/router';
import IconNext from 'assets/pricing/circle-arrow.inline.svg';
import Footer from 'components/Footer';
import Layout from 'components/Layout';
import ButtonIconLeft from 'components/material-ui/ButtonIconLeft';
import SelectTextField from 'components/material-ui/SelectTextField';
import ShoppingCard from 'components/pages/basket/items/ShoppingCard';
import { PRICING_PLAN } from 'components/pages/pricing/base/enumsPricing';
import { handleTranslateMonthsSelect } from 'components/pages/pricing/PricingPage';
import SubPlansContainer from 'components/pages/pricing/view/SubPlansContainer';
import { routes } from 'constants/routes';
import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useAllPlanSetups from 'hooks/useAllPlanSetups';
import useCommonData from 'hooks/useCommonData';
import useLanguage from 'hooks/useLanguage';
import { getSearchParam, searchParams } from 'hooks/useSearchParams';
import { QUERY } from 'interfaces/graph';
import { actionsPersist, usePersistDispatch, usePersistState } from 'providers/PersistProvider';
import { includes } from 'ramda';
import React, { useEffect, useState } from 'react';
import { isMobileScreen } from 'utils/helper';

export default function ProductOptionsSelectionPage() {
	const { t } = useTranslation();
	const location = useLocation();
	const { language } = useLanguage();
	const { option } = usePersistState();
	const dispatchPersist = usePersistDispatch();
	const { allPlanSetups } = useAllPlanSetups();
	const { allPlanDurations } = useCommonData(QUERY.ALL_PLAN_DURATIONS);

	const [disabledPlan, setDisabledPlan] = useState(false);

	useEffect(() => {
		window.scrollTo(0, 0);
		const searchParam = getSearchParam(location.search);

		if (!includes(searchParam, [PRICING_PLAN[1], PRICING_PLAN[2], PRICING_PLAN[3]])) {
			navigate(`${location.pathname}?${searchParams.plan}=${PRICING_PLAN[1]}`);
		}
	}, []);

	const optionAgreement = option ? option : (allPlanDurations ? allPlanDurations[0] : null);

	const formPricing = useFormik({
		initialValues: {
			months: optionAgreement?.id
		},
		enableReinitialize: true
	});

	const isMobile = isMobileScreen();

	return (
		<Layout hideLanguage>
			<div className="relative border-t border-grey-E mt-24 mb-10">
				<div className="sm:text-center mx-15 sm:mx-0">
					{/*TODO: all h1*/}
					<h1 className="mb-14 mt-20 sm:mt-40 text-17 leading-25 sm:text-24">
						{t('pageBasket.productOptionsSelection.plan.h1', { name: getSearchParam(location.search).toUpperCaseChar() })}<span className="text-green-1 font-normal text-shadow-none">_</span>
					</h1>
					<div className="sm:flex items-center justify-center">
						<p className="text-12 mr-30 sm:mr-10 mb-10 sm:mb-0">{t('pageBasket.productOptionsSelection.plan.desc')}</p>
						<SelectTextField
							tw="w-110"
							options={allPlanDurations}
							onChange={event => {
								const id = event.target.value;
								dispatchPersist({ type: actionsPersist.updateOption, payload: allPlanDurations.find(item => item.id === id) });
							}}
							form={formPricing}
							variant="outlined"
							name="months"
							labelName="durationUnit"
							labelSecondName="duration"
							labelFunc={handleTranslateMonthsSelect(language)}
						/>
					</div>
				</div>

				{allPlanSetups?.length && allPlanDurations?.length && (
					<ShoppingCard
						isMobile={isMobile}
						allPlanSetups={allPlanSetups}
						optionAgreement={optionAgreement}
						modeName={getSearchParam(location.search)}
						onAgreementOptionChange={shouldDisabled => setDisabledPlan(shouldDisabled)}
					/>
				)}

				<ButtonIconLeft name="btnNext-1" disabled={disabledPlan} to={routes[language].BASKET_SUMMARY} Icon={IconNext} tw="mx-auto mt-30" size="medium">
					{t('pageBasket.label.btnNext')}
				</ButtonIconLeft>

				{/*	Weight animation */}
				<SubPlansContainer tw="mt-50 sm:mt-50 2xl:mt-80" isMobile={isMobile}/>

				<ButtonIconLeft name="btnNext-2" disabled={disabledPlan} to={routes[language].BASKET_SUMMARY} Icon={IconNext} tw="mx-auto mt-30 mb-60" size="medium">
					{t('pageBasket.label.btnNext')}
				</ButtonIconLeft>
			</div>

			<Footer/>
		</Layout>
	);
}
